<template>
  <NuxtLink
    class="flex size-12 items-center justify-center rounded-sm bg-white text-gray-500 transition-colors duration-200 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-brand-500"
    active-class="!bg-gray-50 !text-brand-600 hover:!bg-gray-100"
    v-bind="linkProps"
  >
    <UiIcon
      :name="icon"
      class="size-5"
    />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { NuxtLinkProps } from '#app'

export type NavigationLink = Omit<NuxtLinkProps, 'custom' | 'activeClass' | 'exactActiveClass'> & {
  icon: string
}

const { icon, ...linkProps } = defineProps<NavigationLink>()
</script>
