<template>
  <div class="h-full">
    <aside class="fixed inset-y-0 left-0 z-sidebar flex border-r border-r-gray-200 bg-white">
      <div
        class="flex h-full w-20 flex-col justify-between"
      >
        <div class="flex flex-col items-center gap-3xl pt-3xl">
          <img
            alt="logomark"
            src="~/assets/images/logomark.svg"
          >
          <UiNavigation
            :items="[
              { to: '/', icon: 'home-line' },
              { to: '/notifications', icon: 'bell-03' },
              { to: '/team', icon: 'users-01' },
            ]"
          />
        </div>
        <div class="flex flex-col items-center gap-3xl pb-3xl">
          <UiNavigation
            :items="[
              { to: 'https://support.changetower.com', external: true, target: '_blank', icon: 'life-buoy-01' },
              { to: '/account', icon: 'settings-01' },
            ]"
          />
          <UiMenu
            placement="right-end"
            :offset="16"
          >
            <template #button>
              <button
                type="button"
                class="rounded-full border-0.75 border-black/10 bg-gray-100 p-1.5 hover:outline hover:outline-2 hover:outline-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-brand-500"
              >
                <UiIcon
                  name="user-01"
                  class="block size-5 text-gray-600"
                />
              </button>
            </template>
            <template #content>
              <div class="w-60 divide-y divide-gray-100 rounded-lg border border-gray-200 bg-white shadow-lg focus:outline-none">
                <div class="flex gap-lg px-4 py-3">
                  <div class="rounded-full border-0.75 border-black/10 bg-gray-100 p-1.5">
                    <UiIcon
                      name="user-01"
                      class="block size-5 text-gray-600"
                    />
                  </div>
                  <div class="flex flex-col">
                    <span class="text-sm font-semibold text-gray-700">{{ user.name }}</span>
                    <span class="text-sm text-gray-600">{{ user.email }}</span>
                  </div>
                </div>
                <div class="px-1.5 py-1">
                  <UiMenuItem v-slot="{ active }">
                    <NuxtLink
                      to="/account"
                      class="flex w-full items-center gap-md rounded-sm px-2.5 py-[9px] transition-colors duration-200"
                      :class="{
                        'text-gray-700': !active,
                        'bg-gray-50 text-gray-800': active,
                      }"
                    >
                      <UiIcon
                        name="user-01"
                        class="size-4 text-gray-500"
                      />
                      <span class="text-sm font-medium">Account Settings</span>
                    </NuxtLink>
                  </UiMenuItem>
                  <UiMenuItem
                    v-if="user.account_type === 'corporate'"
                    v-slot="{ active }"
                  >
                    <NuxtLink
                      to="/account/company"
                      class="flex w-full items-center gap-md rounded-sm px-2.5 py-[9px] transition-colors duration-200"
                      :class="{
                        'text-gray-700': !active,
                        'bg-gray-50 text-gray-800': active,
                      }"
                    >
                      <UiIcon
                        name="building-07"
                        class="size-4 text-gray-500"
                      />
                      <span class="text-sm font-medium">Company Settings</span>
                    </NuxtLink>
                  </UiMenuItem>
                  <UiMenuItem v-slot="{ active }">
                    <button
                      type="button"
                      class="flex w-full items-center gap-md rounded-sm px-2.5 py-[9px] transition-colors duration-200"
                      :class="{
                        'text-gray-700': !active,
                        'bg-gray-50 text-gray-800': active,
                      }"
                      @click="logout"
                    >
                      <UiIcon
                        name="log-out-01"
                        class="size-4 text-gray-500"
                      />
                      <span class="text-sm font-medium">Log out</span>
                    </button>
                  </UiMenuItem>
                </div>
              </div>
            </template>
          </UiMenu>
        </div>
      </div>
      <div
        v-if="hasSubnavigation"
        class="w-52 border-l border-l-gray-200 px-4 py-6"
      >
        <slot name="subnavigation" />
      </div>
    </aside>
    <header
      class="fixed right-0 top-0 z-header flex h-16 items-center justify-between border-b border-b-gray-200 bg-white px-3xl"
      :class="{
        'left-20': !hasSubnavigation,
        'left-72': hasSubnavigation,
      }"
    >
      <slot name="breadcrumbs" />
    </header>
    <main
      class="mt-16 p-3xl"
      :class="{
        'ml-72': hasSubnavigation,
        'ml-20': !hasSubnavigation,
      }"
    >
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
const slots = useSlots()
const hasSubnavigation = computed(() => !!slots.subnavigation)

const user = useAuthenticatedUser()
const { logout } = useSanctumAuth()
</script>
